import { Box, makeStyles } from '@material-ui/core';
import icon from './icon-512.png';

const useStyles = makeStyles({
    icon: {
        width: '120%',
        height: '120%',
        margin: '-10%'
    }
});


function Icon({ className }) {
    const classes = useStyles();

    return (
        <Box className={className}>
            <img className={classes.icon} src={icon} alt="" />
        </Box>
    );
}

export default Icon;