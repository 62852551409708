import { Box, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Icon from './icon/Icon';
import { Playstore, Appstore } from './links/Links';
import Screenshot from './screenshot/screenshot';

const useStyles = makeStyles((theme) => ({
  mdContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.maxWidth,
    margin: 'auto',
    paddingTop: 56,
    paddingBottom: 56
  },
  mdInfoContainer: {
    padding: 56
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  horizontalContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    fontWeight: 'bold',
    paddingLeft: 16,
    paddingRight: 16
  },
  description: {
    width: '100%',
    maxWidth: theme.maxWidth / 2,
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  icon: {
    width: 120,
    height: 120
  },
  buttonsDivider: {
    width: 24,
    height: 24
  }
}));

function Preview() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mdMatches = useMediaQuery(theme => theme.breakpoints.up('md'));
  const smMatches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const title = (<Typography className={classes.title} variant="h3" noWrap>{t('applicationTitle')}</Typography>);
  const description = (<Typography className={classes.description} variant="h5" paragraph>{t('applicationDescription')}</Typography>);
  const icon = (<Icon className={classes.icon} />);

  if (mdMatches) {
    return (
      <Box className={classes.mdContainer}>
        <Screenshot />
        <Box className={clsx(classes.mdInfoContainer, classes.verticalContainer)}>
          <Box className={classes.titleContainer} >
            {icon}
            {title}
          </Box>
          {description}
          <Box className={classes.horizontalContainer}>
            <Appstore />
            <Box className={classes.buttonsDivider} />
            <Playstore />
          </Box>
        </Box>
      </Box>
    );
  } else if (smMatches) {
    return (
      <Box className={classes.verticalContainer}>
        <Box className={classes.titleContainer}>
          {icon}
          {title}
        </Box>
        {description}
        <Box className={classes.horizontalContainer}>
          <Appstore />
          <Box className={classes.buttonsDivider} />
          <Playstore />
        </Box>
        <Screenshot />
      </Box>
    );
  } else {
    return (<Box className={classes.verticalContainer}>
      {icon}
      {title}
      {description}
      <Appstore />
      <Box className={classes.buttonsDivider} />
      <Playstore />
      <Screenshot />
    </Box>)
  }
}

export default Preview;
