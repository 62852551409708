import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Clock from 'mdi-react/ClockIcon';
import FuelPump from 'mdi-react/FuelPumpIcon';
import Wrench from 'mdi-react/WrenchIcon';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        maxWidth: theme.maxWidth,
        margin: 'auto',
        padding: 24
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: theme.palette.text.primary,
    },
    subtitle: {
        flexGrow: 1,
        color: theme.palette.text.secondary,
    },
    iconBackground: {
        width: 36,
        height: 36,
        display: 'flex',
        borderRadius: '50%'
    },
    icon: {
        width: 24,
        height: 24,
        margin: 'auto',
        color: theme.palette.primary.main,
    }
}));

const features = (t) => [
    {
        icon: Clock,
        title: t('featuresTimeTitle'),
        subtitle: t('featuresTimeSubtitle')
    },
    {
        icon: FuelPump,
        title: t('featuresFuelTitle'),
        subtitle: t('featuresFuelSubtitle')
    },
    {
        icon: Wrench,
        title: t('featuresServiceTitle'),
        subtitle: t('featuresServiceSubtitle')
    }
];

function Features() {
    const classes = useStyles();
    const { t } = useTranslation();

    function renderItem(item) {

        return (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
                <Paper className={classes.itemContainer}>
                    <Paper className={classes.iconBackground}> {<item.icon className={classes.icon} />} </Paper>
                    <Box className={classes.textContainer}>
                        <Typography className={classes.title} variant="h5" noWrap>{item.title}</Typography>
                        <Typography className={classes.subtitle} variant="body1">{item.subtitle}</Typography>
                    </Box>
                </Paper>
            </Grid>
        );
    }

    return (
        <Box className={classes.gridContainer}>
            <Grid container spacing={3}>
                {features(t).map(renderItem)}
            </Grid>
        </Box>
    );
}

export default Features;
