import { Box, makeStyles } from '@material-ui/core';
import Header from '../components/Header';
import Preview from '../components/Preview';
import Features from '../components/Features';
import Footer from '../components/Footer';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
}));

function Info() {
  const classes = useStyles();

  return (
    <Box className={classes.infoContainer}>
      <Header />
      <Preview />
      <Features />
      <Footer />
    </Box>
  );
}

export default Info;
