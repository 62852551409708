import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import frame from './frame.png'

const useStyles = makeStyles({
  screenshotContainer: {
    maxWidth: 280,
    maxHeight: 500,
    margin: 20,
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
});

function Screenshot() {
  const classes = useStyles();

  return (<Box className={classes.screenshotContainer}>
    <img className={classes.image} src={frame} alt="" />
  </Box>);
}

export default Screenshot;