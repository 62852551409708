import React from "react";
import { Box, AppBar, Toolbar, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Icon from './icon/Icon';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    maxWidth: theme.maxWidth,
    margin: 'auto'
  },
  title: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  link: {
    padding: 8,
    color: theme.palette.primary.contrastText
  },
  icon: {
    width: 56,
    height: 56,
    padding: 8
  }
}));

function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <AppBar position="static" >
        <Toolbar className={classes.header}>
          <Icon className={classes.icon} />
          <Typography className={classes.title} variant="h4" noWrap>
            {t('applicationTitle')}
          </Typography>
          <Link to="/privacy">
            <Typography className={classes.link} variant="h5" noWrap>
              {t('privacyPolicy')}
            </Typography>
          </Link>
          <Link to="/terms">
            <Typography className={classes.link} variant="h5" noWrap>
              {t('termsOfUse')}
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Header;